import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["item", "count"];

  connect() {
    super.connect();
    console.log("did connect filter_controller");
  }

  filter(e) {
    console.log("filter by: " + e.target.value);

    let filterValue = e.target.value.toUpperCase();
    var categories = ["undefined"];
    var categories_counters = [0];

    this.itemTargets.forEach(function (el, key, listObj) {
      let txtValue = el.textContent || el.innerText;
      // console.log(`Element ${el}:  ${txtValue} \n`);
      if (txtValue.toUpperCase().indexOf(filterValue) > -1) {
        el.style.display = "";
        if (el.dataset.filterCategory != undefined) {
          let cat = el.dataset.filterCategory;
          console.log("category: " + cat + " json: " + categories.join(" "));
          if (!categories.includes(cat)) {
            categories.push(cat);
            categories_counters[categories.length - 1] = 0;
          }
          let index = categories.indexOf(cat);
          categories_counters[index]++;
        } else {
          categories_counters[0]++;
        }
      } else {
        el.style.display = "none";
      }
    }, "arg");

    var htmlout = "";
    // create output string
    if (categories.length > 1) {
      for (let i = 1; i < categories.length; i++) {
        htmlout = htmlout + categories_counters[i] + " " + categories[i] + " ";
      }
    } else {
      htmlout = htmlout + categories_counters[0] + " Ergebnisse";
    }
    if (this.countTarget != undefined) {
      this.countTarget.innerHTML = htmlout;
    }
  }
}
